<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="disciplinas.total > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th style="width: 20px;"></th>
                        <th>Disciplina</th>
                        <th style="width: 100px">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(d, i) in disciplinas.lista" :key="i">
                        <td class="pr-0">
                            <v-avatar size="40" :color="corAvatar(d.disciplina)">
                                <span class="white--text headline">{{d.disciplina.substr(0, 1)}}</span>
                            </v-avatar>
                        </td>
                        <td :key="d.id_disciplina">
                            <router-link exact color="primary" v-if="permissao('disciplina_abrir')" :to="`/disciplina/gerenciar/${d.id_disciplina}`"><strong>{{d.disciplina}}</strong></router-link>
                            <strong v-else>{{d.disciplina}}</strong><br/>
                            <small><strong>ID:</strong> {{d.id_disciplina}}</small>
                        </td>
                        <td>
                            <v-chip small color="green" dark v-if="d.status === 't'"><v-icon left small>mdi-check-circle</v-icon> Ativa</v-chip>
                            <v-chip small color="red" dark v-else><v-icon left small>mdi-close-circle</v-icon> Inativa</v-chip>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao @paginar="pagination" :total="disciplinas.total" />
    </div>
</template>

<script>

import Paginacao from '@/Views/Estrutura/Paginacao'
import {mapState} from "vuex"
import axios from "axios"

export default {
    name: "DisciplinaListar",
    props : ['filtro'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            disciplinas : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['baseUrl', 'paginationLimit'])
    },
    methods : {
        // paginate
        pagination(pagina) {
            this.carregando = true
            return axios.post(`${this.baseUrl}disciplina/list`, {
                pagina,
                limit  : this.paginationLimit,
                offset : this.paginationLimit * pagina,
                busca  : this.filtro.busca || null
            }).then( (res) => {
                this.disciplinas = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'filtro.busca' : function () {
            this.pagination(0)
        },
    },
    activated() {
        this.pagination(0)
    }
}
</script>

<style scoped>

</style>
